<template>
  <div class="content-loader" :class="{'content-loader__center':center}">
    <div class="lds-dual-ring"></div>
  </div>
</template>

<script>
export default {
  name: "ContentPreloader",
  props: {
    center: Boolean
  }
}
</script>

<style lang="scss" scoped>
.content-loader {
  display: flex;
  justify-content: center;

  &__center{
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 48px;
  height: 48px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--color-primary);
  border-color: var(--color-primary) transparent var(--color-primary) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
